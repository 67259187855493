import React from 'react';
import { render } from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import App from './app';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d69f4e',
    },
    secondary: {
      main: '#ffffff',
    },
    black: {
      main: '#000'
    },
  }
})

const app = document.querySelector('#app');

render(
  <CssBaseline>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </CssBaseline>,
  app
)