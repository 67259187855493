import './authors.css';
import React from 'react';
import { useHistory } from 'react-router-dom';
import MultiRight from './multiRight';
import MultiLeft from './multiLeft';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import withStyles from '@material-ui/styles/withStyles';

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
});

const MultiAuthor = ({ chapter, classes }) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  }
  return (
    <div className={chapter.ch_num % 2 !== 0 ? `${classes.toolbar} multiBioBgBlue f-column center` : `${classes.toolbar} multiBioBg f-column center`}>
      <div className='multiWrapper'>
        <div className='multi-author-button-container'>
          <Button style={{ margin: '1rem' }} onClick={goBack}>
            <ArrowBackIcon fontSize='large' />
          </Button>
        </div>
        <h1 className='logoMain textShadow multiHeader' style={{ textAlign: 'center'}}>{chapter.chapter_title}</h1>
          {
            chapter.authors.map((author, idx) => 
              idx % 2 !== 0 
              ? <MultiRight 
                  key={idx}
                  author={author}
                /> 
              : <MultiLeft 
                  key={idx}
                  author={author}
                />
            )
          }
      </div>
    </div>
  );
};

export default withStyles(styles)(MultiAuthor);
