import React from 'react';

const MultiLeft = ({ author }) => {
  return (
    <div className='f center multiTopBio'>
      <div className='imageContainer f center bioLeft'>
        <img src={author.image} className='image boxShadow' alt={author.name} />
      </div>
      <div className='f-column center multiBioRight'>
        <p className='med fontMain textShadow name bold-light grey'>{author.name}</p>
        {author.bio}
      </div>
    </div>
  )
};

export default MultiLeft;
