export const validateEmail = em => 
/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(em);

export const formatNames = (authors) => {
  return authors.reduce((a, b, i) => {
    if (i === authors.length - 1) {
      a += `& ${b.name}`
    } else {
      a += `${b.name}, `
    }
    return a;
  }, '')
}