import React from 'react';
import './home.css';

const PreOrderLink = ({ image, text, link, target, resize }) => {
  if (image) {
    return (
      <a href={link} target={target ? '_blank' : ''} className='home-a-tag' style={{ textDecoration:'none'}}>
        <button className='pre-order-link boxShadow'>
          <img src={image} alt='icon' className={resize ? 're-size' : ''} />
        </button>
      </a>
    )
  }
  if (text) {
    return (
      <a href={link} target={target ? '_blank' : ''} style={{ textDecoration:'none'}}>
        <button className='pre-order-link boxShadow'>
          {text}
        </button>
      </a>
    )
  }
};

export default PreOrderLink;
