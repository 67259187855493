import './chapters.css';
import React, { useRef, useState, useEffect } from 'react';
import { formatNames } from '../../utils';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const ChapterCard = ({ chapter, selected, setSelected }) => {
  const card = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();

  // useEffect(() => {
  //   const handleWindowResize = () => setWidth(window.innerWidth)
  //   window.addEventListener("resize", handleWindowResize);
  //   return () => window.removeEventListener("resize", handleWindowResize);
  // }, []);

  const openCard = () => {
    if (chapter.ch_num === selected) {
      setSelected(null);
    } else {
      const scrollOptions = { behavior: 'smooth', block: 'start' }
      // width > 600 ? scrollOptions.block = 'start' : scrollOptions.block = 'center';
      // scrollOptions.block = 'start';
      setSelected(chapter.ch_num);
      setTimeout(() => {
        card.current.scrollIntoView(scrollOptions);
      }, 500)
    }
  }

  const redirect = () => {
    history.push(`/chapters/${chapter.chapter}`)
  }

  return (
    <div ref={card} className='chapters-chapter-card boxShadow'>
      <h1>{chapter.chapter_title}</h1>
      {
        chapter.authors
        ? <h3>{formatNames(chapter.authors)}</h3>
        : <h3>{chapter.name}</h3>
      }
      <div className={chapter.ch_num === selected ? 'chapters-hidden-container open' : 'chapters-hidden-container'}>
        <p>{chapter.description}</p>
        {
          chapter.authors
          ? <div onClick={redirect} className='chapters-author-button'>About The Authors</div>
          : <div onClick={redirect} className='chapters-author-button'>About The Author</div>
        }
      </div>
      <div className='chapters-button-container'>
        <div onClick={openCard} className='chapters-expand-button boxShadow'>
          <div className={chapter.ch_num === selected ? 'chapters-expand-icon expand-open' : 'chapters-expand-icon'}>
            <ExpandMoreIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChapterCard;
