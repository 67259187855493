import React from 'react';

const MultiRight = ({ author }) => {
  return (
    <div className='f center multiBottomBio' style={{ marginBottom: '50px' }}>
      <div className='f-column center multiBioRight'>
        <p className='med fontMain textShadow name bold-light grey'>{author.name}</p>
        {author.bio}
      </div>
      <div className='imageContainer f center bioLeft' style={{ width: '40%'}}>
        <img src={author.image} className='image boxShadow' alt={author.name} />
      </div>
    </div>
  );
};

export default MultiRight;
