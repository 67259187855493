import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './components/home/home';
import Editors from './components/editors/editors';
import Chapters from './components/chapters/chapters';
import Navbar from './components/navbar/navbar';
import AuthorBio from './components/authors/authorBio';
import Editorials from './components/editorials/editorials';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/editors' component={Editors} />
            <Route path='/reviews' component={Editorials} />
            <Route exact path='/chapters' component={Chapters} />
            <Route path='/chapters/:chapter' component={AuthorBio} />
            <Redirect to='/' />
          </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App;
