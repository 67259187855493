export const landingReviews = [
  {
    quote: `Dr. Benedicto Elden and Dr. Shanok's “Thriving in Graduate School: The Experts Guide to Success and Wellness” explores common struggles faced by historically marginalized students - such as impostor syndrome, microaggressions, multicultural competence, and funding.  Graduate programs throughout the country should have a copy of this book to share with their students.`,
    by: `E. J. R. David, Associate Professor of Psychology at the University of Alaska Anchorage`
  },
  {
    quote: `Arielle Shanok and Nicole Benedicto Elden offer you a GPS for navigating the joyful and infuriating pathway to graduation and to re-member your intellectual projects of desire and purpose.`,
    by: `Michelle Fine, Professor of Critical Psychology, Women’s Studies, American Studies and Urban Education`
  },
  {
    quote: `Through a simple, engaging, and casual language, the authors demystify the experience of graduate work normalizing it and validating the tough experiences lived by students. Issues such as impostor syndrome, procrastination, and anxiety are presented in a candid way offering self-reflection exercises, and wisdom to empower students to overcome these and other challenges.`,
    by: `Dinelia Rosa, Ph.D., Director of the Dean-Hope Center of Educational & Psychological Services`
  },
  {
    quote: `This astute book touches all the right chords in addressing the stressors that students across multiple graduate programs inevitably encounter.`,
    by: `Barry A. Farber, PhD, professor and the former Director of Clinical Training at Teachers College, Columbia University’s Program in Clinical Psychology`
  }
];
