import './authors.css';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
});

const SingleAuthor = ({ author, classes }) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  }
  return (
    <div className={author.ch_num % 2 !== 0 ? `${classes.toolbar} bioBgBlue f` : `${classes.toolbar} bioBg f`}>
      <div className='single-author-button-container'>
        <Button style={{ margin: '1rem' }} onClick={goBack}>
          <ArrowBackIcon fontSize='large' />
        </Button>
      </div>
      <div className='bioLeft f center'>
        <div className='imageContainer f center'>
          <img src={author.image} className='image boxShadow' alt={author.name} />
        </div>
      </div>
      <div className='bioRight f-column center'>
        <h1 className='logoMain textShadow'>{author.chapter_title}</h1>
        <p className='med fontMain textShadow name bold-light grey'>{author.name}</p>
        {author.bio}
      </div>
    </div>
  )
};

export default withStyles(styles)(SingleAuthor);
