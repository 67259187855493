import React from 'react';
import shanok from '../public/assets/authors/shanok.png';
import mangan from '../public/assets/authors/mangan.png';
import wisely from '../public/assets/authors/wisely.png';
import strama from '../public/assets/authors/strama.png';
import hua from '../public/assets/authors/hua.png';
import baker from '../public/assets/authors/baker.png';
import osei from '../public/assets/authors/osei.png';
import ruck from '../public/assets/authors/ruck.png';
import gilles from '../public/assets/authors/gilles.png';
import elisha from '../public/assets/authors/elisha.png';
import elden from '../public/assets/authors/elden.png';
import renn from '../public/assets/authors/renn.png';
import beemyn from '../public/assets/authors/beemyn.png';
import goldberg from '../public/assets/authors/goldberg.png';
import zonana from '../public/assets/authors/zonana.png';
import camba from '../public/assets/authors/camba.png';
import schulz from '../public/assets/authors/schulz.png';
import newman from '../public/assets/authors/newman.png';
import starr from '../public/assets/authors/starr.png';
import gertmenian from '../public/assets/authors/gertmenian.png';
import lee from '../public/assets/authors/lee.png';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LanguageIcon from '@material-ui/icons/Language';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import PhoneIcon from '@material-ui/icons/Phone';

const authors = [
  {
    name: 'Arielle F. Shanok, PhD',
    bio: <p className='reg fontMain'><b>Arielle F. Shanok, PhD</b> is the Deputy Director at the Graduate Center, City University’s Student Counseling Services where she has helped graduate students to thrive for over a decade. Dr. Shanok earned her PhD in Clinical Psychology at Teachers College, Columbia University and her BA at Wesleyan University. She previously taught at Barnard College, Columbia University. Dr. Shanok has published book chapters and articles in peer reviewed journals on a range of topics from psychotherapy effectiveness to gender and money in therapy to pregnant and parenting teen students. She has a small private practice in mid-town Manhattan. Dr. Shanok provides consultations and counseling to graduate students in individual, couples and group contexts integrating psychodynamic, cognitive behavioral, dialectical behavioral, emotion focused, and systems approaches. She also provides consultations to faculty. Dr. Shanok grew up in a multicultural, social justice-oriented family. Her approach is informed by compassion, multicultural humility and a strength focus.</p>,
    chapter: 'one',
    ch_num: 1,
    image: shanok,
    chapter_title: 'Welcome! What You Need to Know About Graduate School',
    description: 'Grad School is its own unique world with many unspoken rules. The sooner that you can understand and acclimatize to your new surroundings, the faster you can channel your efforts in fruitful directions. This introductory chapter illuminates how success is defined in grad school and invites you to clarify and possibly modify your grad school goals, work/study habits and expectations of yourself. You will learn how to use your goals as navigational systems, guiding your choices amidst the overwhelming array of options you will face. If your goals are not clear, you will learn ways to better see your inner compass. You will be invited to adjust internal rules that may be self-destructive in grad school (such as “anything short of perfection is failure”) to more productive ones (“I’ll do the best I can with the time and resources I have.”) Guidance on building and sustaining relationships in and outside of graduate school is provided. Additional strategies for maintaining mental and physical health while confronting common grad school challenges (from the endless sea of work to experiences of discrimination) are included. Examples, interwoven throughout, bring the chapter to life!',
    nav_title: 'Welcome! What you Need to Know',
  },
  {
    name: 'Dr. Alice Mangan',
    bio: <p className='reg fontMain'><b>Dr. Alice Mangan</b> holds a Ph.D. in clinical psychology from CUNY Graduate Center and a master’s degree in special education from Bank Street College of Education.  Formerly a member of the graduate faculty at Bank Street College of Education, Dr. Mangan has a private practice in New York City where she works clinically with adults, adolescents, and children as well as parents and families to provide consultation, assessment and evaluation, and psychotherapy services.  Dr. Mangan is also a consulting psychologist at schools, companies and start-ups, and advises authors and editors of children’s books.  Dr. Mangan’s research interests center on the relationships between schools and families and the influence of learning disabilities on the psychological and relational life of the child, parent, and family system.  Dr. Mangan has a strong commitment to sensitively addressing concerns related to learning and learning disability, autism spectrum disorders, the interplay between disability and social, emotional and psychological development, parenting and family life, and other crucial and intersecting dimensions of identity such as gender, sexuality, race and ethnicity.</p>,
    chapter: 'two',
    ch_num: 2,
    image: mangan,
    chapter_title: 'Faking It: The Impostor Syndrome in Graduate School',
    description: 'Alongside the gratification that many students feel upon acceptance into a graduate program, many may also hold feelings of acute fraudulence.  Indeed, feeling like a fake and living in fear of having one’s fraudulence exposed or one’s luck run out can be pervasive among those who join the ranks of academia.  In the late 1970s, Drs. Pauline Clance and Suzanne Imes first coined the Imposter Phenomenon after extensive observations and interviews with women in academia.  Since that time, the Imposter Phenomenon has been identified as a fairly common human experience, extending across gender, race, ethnicity and class as well as other dimensions of identity.  This chapter offers you a deeper look at imposterism, detailing the ways in which it manifests, and presenting the many factors that produce and sustain it.  You will be given numerous strategies for combating their experience of imposterism.  Fortified by greater awareness and new resources, my hope is you will no longer feel like you are faking your way through graduate school, and instead, experience genuine and authentic connection to your actual abilities, growth areas, struggles and successes.',
    nav_title: 'Imposter Syndrome',
  },
  {
    name: 'Dr. Lauren Wisely',
    bio: <p className='reg fontMain'><b>Dr. Lauren Wisely</b> is a clinical psychologist in private practice, specializing in the treatment of relationship issues, anxiety and trauma. Lauren attended graduate school at the Long Island University’s CW Post Clinical Psychology Doctoral Program. She has a private practice in (<a href='https://www.delawarepsychologicalservices.com/' target="_blank">delawarepsychologicalservices.com</a>) where she treats children, teens, college students and adult populations.  She has extensive training and experience in psychodynamic, cognitive behavioral, DBT and trauma focused psychotherapy. A travel and photography enthusiast; Lauren now resides in the quiet seaside town of Lewes, Delaware with her husband, three children and their menagerie of family pets.</p>,
    chapter: 'three',
    ch_num: 3,
    image: wisely,
    chapter_title: 'So… it’s not just me? Coping with Stress and Anxiety in Graduate School',
    description: 'Stress and anxiety are as much an obligatory part of grad school as studying and taking exams. That doesn’t mean you are signing on for years of misery; in fact this can be a time in your life to learn how to best manage high-level stress in positive and productive ways. Anxiety can be a motivator, but it can also grow in ways that can quickly become overwhelming. This chapter will explore ways to develop skills necessary for handling whatever grad school may throw at you. We will review common problematic thinking patterns among grad students, and the strategies for changing them or turning down their volume. Additionally, we will cover tools and exercises to build healthy coping skills and a sense of well-being, including realistic self-care for people who don’t have time for self-care. Grad school offers many challenges, and not least among them are strains on your mental health. This chapter will hopefully guide you through the process of making peace with the stress to come while also finding the ability to help manage it.',
    nav_title: 'Coping with Stress and Anxiety',
  },
  {
    name: 'Inez Strama, PsyD, CGP',
    bio: <p className='reg fontMain'><b>Dr. Inez Strama</b> is a supervising clinical psychologist and certified group psychotherapist at the Student Counseling Service of the Graduate Center, CUNY. She works exclusively with master’s and doctoral level graduate students in individual and group psychotherapy, as well as in training and clinical supervision. She has been trained in trauma-informed, psychodynamic, dialectical behavioral, and Buddhist/contemplative/mindfulness-based therapies. She integrates these modalities according to each student’s unique strengths and needs. She has conducted research on social class and ethnic identity formation in 1.5-generation immigrants. She is also interested in the sequelae of developmental & acute trauma, and finds the work with students who have experienced this kind of suffering particularly meaningful and rewarding. Her loving partner and two cat children teach, inspire, and amaze her every day. She would like to thank her former and current colleagues working in disability services for sharing their expertise as she was writing her chapter, namely Vincent Kiefner, Ph.D., Anna Riquier, LMHC, and Clare Wilson.</p>,
    chapter: 'four',
    ch_num: 4,
    image: strama,
    chapter_title: 'The Struggle is Real: Accessing Help from Counseling and Disability Services',
    description: 'This chapter is about normalizing how very real the struggle of grad school can be! It tells you what you can expect from counseling and disability services, and discusses the unique challenges and complexities of disclosing a disability as a grad student. It also identifies some common barriers and obstacles that get in the way of asking for help, busts some myths that contribute to the stigma, and hopefully, de-mystifies the whole process so that you’re more likely to seek out help if you find yourself needing it. Lastly, it also briefly highlights some other student support services that may help you. Taken together, the hope is that by the end of the chapter you will feel more knowledgeable about and empowered to utilize all of the services available to you during your tenure as a grad student.',
    nav_title: 'Accessing Help',
  },
  {
    name: 'Vivi Wei-Chun Hua, Psy.D',
    bio: (<div>
      <p className='reg fontMain'><b>Vivi Wei-Chun Hua, Psy.D</b> is a clinical psychologist and an international student success coach. She received her doctorate from Ferkauf Graduate School of Psychology at Yeshiva University in Bronx, NY. Originally from Taiwan, Dr. Hua is particularly insightful about the unique challenges of studying in the U.S. as a foreign student. She provides coaching services specifically designed to help international students achieve academic, professional, and personal success in the U.S. In addition to coaching, Dr. Hua runs a successful psychology practice in New York City and provides psychotherapy for adolescents and adults suffering from depression, anxiety, trauma, or cross-cultural adjustment issues. Through therapy, Dr. Hua empowers her clients to be their authentic selves and to break free of any psychological barriers, as a result of individual, societal, or systemic forces of oppression. Dr. Hua also provides clinical supervision to doctoral-level psychology students.</p>
      <p className='reg fontMain'>For more information about Dr. Hua’s coaching services for international students and to get in touch with Dr. Hua, visit <a href="https://www.drvivicoaching.com/" target="_blank">DrViviCoaching.com</a></p>
      <p className='reg fontMain'>International students are welcome to join Dr. Hua’s <a href='https://www.facebook.com/groups/internationalstudentsuccess' target='_blank'>Facebook community</a> to connect with peers and seek support from one another</p>
    </div>
    ),
    chapter: 'five',
    ch_num: 5,
    image: hua,
    chapter_title: 'The Power of Being Different: Navigating Grad School as International Students',
    description: 'When I first landed in the U.S. 14 years ago for my graduate studies, I had no idea that what was going to unfold in front of me would transform how I understand the world, how I define myself, and how I approach people. There will be many challenges ahead of you as an international student; some of the challenges are likely beyond how to do well in school itself and ultimately about your personal growth, your values, and your identity. For many of you, being an international student may feel quite lonely. In this chapter, I share my own journey in hopes that you will find comfort and strength as you tackle each hurdle along the way and achieve your goals. You will learn key skills to help you better navigate the underlying cultural factor of many challenges you will encounter. Practical tips are also provided to help ensure you start this exciting journey on the right foot.',
    nav_title: 'International Students',
  },
  {
    name: 'Kristan Baker, PsyD',
    bio: <p className='reg fontMain'><b>Kristan Baker, PsyD</b> is a NY licensed Psychologist with over 20 years of experience working in the field. Dr. Baker has a private practice in Huntington, NY., providing therapy services for teens and adults. (<a href='https://www.bakerbesemerpsychologists.com/' target="_blank">bakerbesemerpsychologists.com</a>) Since 2003, she has worked as program director, administrator and clinician for MercyFirst, a non-profit agency that serves youth who have been placed in the child welfare and/or juvenile justice systems. Her work focuses on addressing intergenerational trauma and community safety. In addition, she has focused on training and supervising clinical and program staff regarding trauma-informed interventions. Dr. Baker earned her doctorate in Clinical Psychology from Long Island University, Post Campus, with training in cognitive-behavioral, trauma-focused CBT, dialectical-behavioral and psychodynamic theories.</p>,
    chapter: 'six',
    ch_num: 6,
    image: baker,
    chapter_title: 'Navigating Group Dynamics in Graduate School',
    description: 'Congratulations! You’ve made it into graduate school! It is a given that you will gain lots of academic experiences during your time in graduate school, but one of the most important lessons you will learn is non-academic. It is this: You must learn how to navigate interpersonal and group dynamics. Understanding this is one of the crucial keys to your success in graduate school and beyond. Groups in graduate school come in all different shapes and sizes. The common denominator for any group scenario is you and what you bring to the table in terms of your wants, needs, characteristics and goals. As you approach your graduate school experience, it is a good time to take a look at your interpersonal toolbox and see what you have, and what you might need, to aid you on this journey through graduate school. Topics covered in this chapter include: Understanding how family dynamics, family roles, as well as interpersonal needs, can play a part in how you interact with others; Understanding how comfortable and confident you are with competition; Recognizing the rewards and challenges of standing out and fitting in your program; Identifying some common challenging group dynamics and tips for how to manage them.',
    nav_title: 'Group Dynamics in Grad School',
  },
  {
    name: 'Dr. Adjoa Osei',
    bio: (<div>
      <p className='reg fontMain'><b>Dr. Adjoa Osei</b> is a licensed clinical psychologist who received her doctorate from Long Island University, Post campus with a concentration in serious mental illness. Her dissertation focused on examining how Black clinical and counseling psychology doctoral students experienced navigating personal and professional relationships within their academic program. She was trained in both psychodynamic psychotherapy and cognitive-behavioral therapy (CBT), which she uses in her virtual private practice and in her private practice, located in Brooklyn, NY.  She practices from a trauma-informed, culturally sensitive approach. Her private practice is founded on the perspective that identity is integral to therapeutic work and thus, she views a person and their presenting problems within a cultural context.</p>
      <p className='reg fontMain'><LanguageIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='https://www.adjoaoseipsyd.com/' target='_blank'>adjoaoseipsyd.com</a></p>
    </div>
    ),
    chapter: 'seven',
    ch_num: 7,
    image: osei,
    chapter_title: 'Bearing the Baggage of Racial Microaggressions in Graduate School: A Black Woman’s Reflections on Ways to Lessen the Load',
    description: 'Are you struggling with how to respond to interpersonal racism in your academic program? You are not alone! As graduate students of color, it often feels like we are being held to a different standard and carrying an additional load of academic and social challenges centered around race-related experiences. We routinely encounter racial microaggressions and it can be difficult to process your own thoughts and feelings and think of an appropriate intervention that will not harm your academic career. By sharing my own personal examples in this chapter and using self-reflection exercises, I hope to validate your experiences around racial microaggressions in higher education and provide strategies that you can use to address them.',
    nav_title: 'Racial Microaggressions in Grad School',
  },
  {
    chapter: 'eight',
    chapter_title: 'Cultivating and Maintaining Good Mentorship',
    description: 'Good mentorship in graduate school is integral to student success and wellbeing. Getting the mentorship you need will take work. There are some important things to keep in mind as you consider how to and how not to choose an advisor. Since some strategies work better than others, our chapter will give advice for how to: work effectively with your advisor, address problems when they arise, and get your mentorship needs met inside and outside of the advisory relationship. Knowing how to tackle different situations that may come up with an advisor can help you head off problems even before they start, so throughout the chapter we use practical examples and end with an exercise to help you identify your goals and mentorship needs.',
    ch_num: 8,
    nav_title: 'Mentorship',
    authors: [
      {
        name: 'Dr. Juliana Karras-Jean Gilles',
        bio: <p className='reg fontMain'><b>Dr. Juliana Karras-Jean Gilles</b> is an Assistant Professor in the Department of Psychology at San Francisco State University. She completed her: postdoc in Human Development & Psychology at the University of California, Los Angeles; Ph.D. in Developmental Psychology at the Graduate Center City University of New York; and M.A. in Human Development & Social Intervention at New York University. Her multi-method work centers inequality by using a structural lens to study the social development of children and adolescents in context. Specifically: inequality in civic development; the human rights of children; and ethnic-racial inequality across contexts. The goal of her work is to advance social justice through actionable science by generating empirical knowledge that researchers, practitioners, and policymakers can use to identify and rectify social systems that reproduce inequality in development.</p>,
        image: gilles,
      },
      {
        name: 'Dr. Isabelle M. Elisha',
        bio: <p className='reg fontMain'><b>Dr. Isabelle M. Elisha</b> is the Associate Director of the Psychology Programs at CUNY's School
        of Professional Studies. Her work focuses on cultural and contextual influences on children's
        and adolescents’ developmental trajectories with a strong emphasis on the roles of race,
        ethnicity, gender, and socio-economic status. Her current research examines young people’s
        experiences, perceptions, and reasoning about civic and community engagement and their
        thinking about human rights. The goal of her work is to promote empirically-based solutions that
        meaningfully address the negative impact of discrimination, inequality and injustice on young
        people's well-being</p>,
        image: elisha,
      },
      {
        name: 'Martin D. Ruck',
        bio: <p className='reg fontMain'><b>Martin D. Ruck</b> is Professor of Psychology and Urban Education at the Graduate Center of the City University of New York. His research examines the overall process of cognitive socialization—at the intersection of race, ethnicity and class—in terms of children and adolescents’ thinking about human rights, equity, and social justice. Much of his research has addressed how children and adolescents view their protection and participation rights across various cultural contexts. His work has appeared in journals such as <i>Applied Developmental Science, Child Development, Group Processes and Intergroup Relations, International Journal of Children’s Rights, Journal of Adolescence, Journal of Applied Social Psychology, Journal of Early Adolescence, Journal of Educational Psychology, Journal of Research on Adolescence, Journal of Social Issues, and Journal of Youth and Adolescence.</i> He is currently a member of the editorial boards for Human Development and the <i>Journal of Social Issues</i> and is an Associate Editor for <i>Developmental Psychology.</i> He is co-editor with Stacey S. Horn and Lynn S. Liben of the 2-volume <i>Equity and Justice in Development Science</i> (Elsevier, 2016). With Michele Peterson-Badali and Michael Freeman he is co-editor of the <i>Handbook of Children’s Rights: Global and Multidisciplinary Perspective</i> (Taylor & Francis, 2017). Currently, Dr. Ruck serves as Senior Advisor for Diversity and Inclusion to the President of the Graduate Center and is the Executive Officer of the Office of Educational Opportunity and Diversity (EOD).</p>,
        image: ruck,
      }
    ]
  },
  {
    name: 'Nicole Benedicto Elden, Psy.D',
    bio: (
      <div>
        <p className='reg fontMain'><b>Nicole Benedicto Elden, Psy.D.</b> is the Assistant Director of Student Counseling Services, The CUNY Graduate Center. She has worked in various settings including college counseling centers, city, state and research hospitals, government agencies and community centers and nursing homes. Dr. Elden is a 1st generation Filipina-American who immigrated to the US as a teenager.  She is the first in her family to obtain a doctoral degree and is proud to have the opportunity to help and mentor graduate students through their journey, particularly one and a half generation immigrants and graduate student parents.   Her therapeutic approach is integrative with a focus on multicultural humility. She has been trained in dynamic, interpersonal, supportive, cognitive behavioral and dialectical behavioral therapies and family systems. She has been involved in research in psychotherapy techniques and graduate student development and supervision. Her interests include cross-cultural and minority identity formation, the immigration experience, couples and parenting, interpersonal and relationship issues, and adjustment to life transitions. She has presented on various topics throughout the years in many different settings.  With this book she is very proud to add editor and author to her accomplishments.</p>
        <p className='reg fontMain'><b>Dr. Nicole Benedicto Elden's Private Practice</b></p>
        <p className='reg fontMain'><MailOutlineIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='mailto: Dr.Elden@yahoo.com'>Dr.Elden@yahoo.com</a></p>
        <p className='reg fontMain'><PhoneIcon style={{ verticalAlign: 'bottom' }} />917-482-5705</p>
        <p className='reg fontMain'><LanguageIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='https://nicolebenedictoelden.clientsecure.me/' target='_blank'>nicolebenedictoelden.clientsecure.me/</a></p>
        <p className='reg fontMain'><LanguageIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='https://www.psychologytoday.com/profile/952611' target='_blank'>Psychology Today</a></p>
      </div>
    ),
    chapter: 'nine',
    ch_num: 9,
    image: elden,
    chapter_title: 'When Thesis Meets Diapers – Journeys of Graduate Student Parents',
    description: 'Graduate student parents, this chapter is written just for you! I know that there is little understanding for how hard you are working and how much you are juggling moment to moment. This chapter hopes to validate your resilience and courage and highlight that you are a grad parent warrior! Many of you may take longer than average to complete your degrees and may have to seek help from every corner of your lives--a difficult new experience for some. This chapter addresses the challenge of juggling the various tasks of both identities (graduate student and parent) and elucidates the myriad factors that may impede you from feeling that your work and your parenting is ever “good enough.” In addition, many of you will grapple with faculty and institutions ill equipped to support you and some may encounter overt sexism, racism and homophobia along the way.  Despite these distressing challenges, more than half of you will still obtain your degrees. How do you achieve this remarkable feat? This chapter offers parents in graduate school, like you, the opportunity to reflect on your experience, validates and normalizes the incredible challenges you may encounter and offers strategies straight from the experiences of those who know your journey best - other grad student parents past and present.',
    nav_title: 'Grad Student Parents',
  },
  {
    name: 'Kristen Renn, PhD',
    bio: (<div>
      <p className='reg fontMain'><b>Kristen Renn, PhD</b> is Professor of Higher, Adult, & Lifelong Education at Michigan State University and serves as Associate Dean of Undergraduate Studies for Student Success Research. With a background in student affairs administration, including inaugurating the role of LGBTQ resource provider at Brown University, she has for the last 20 years focused her research on the identities, experiences, and development of minoritized students in higher education. She is co-PI of the National Study of LGBTQ Student Success, a two-phase study of LGBTQ college students comprising a mixed methods survey/interview phase and a four-year longitudinal interview study conducted with LGBTQ students. </p>
      <p className='reg fontMain'><MailOutlineIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='mailto: renn@msu.edu'>renn@msu.edu</a></p>
      <p className='reg fontMain'><LanguageIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='http://renn.msu.domains/' target="_blank">renn.msu.domains</a></p>
      <p className='reg fontMain'><TwitterIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='https://twitter.com/krisrenn' target="_blank">@krisrenn</a></p>
    </div>),
    chapter: 'ten',
    ch_num: 10,
    image: renn,
    chapter_title: `“We’re here, we’re queer!” LGBTQ in Graduate School`,
    description: 'Queer grad students, this chapter is for you! As a queer person, you are likely already accustomed to constant hypervigilance about whether situations are safe—and accompanying decisions about whether, and if so how, to reveal your queer indentity(ies). This chapter helps queer students with diverse intersecting identities to be aware of and think through the many decisions that lie ahead from choosing housing and roommates, to deciding whether to come out in one’s lab or teaching roles to tailoring one’s dossier for the job market. Most importantly, this chapter emphasizes that there is no one right way to be queer, and that decisions are often made based on context. Examples of queer grad students and how they have navigated through challenging situations are used to illustrate the diverse ways that queer grad students find to survive and thrive. Recommendations are made to universities on how to be more queer-inclusive.',
    nav_title: 'Queer in Grad School',
  },
  {
    chapter: 'eleven',
    chapter_title: `“To Be Your Best Self”: Surviving and Thriving as a Trans Grad Student`,
    description: 'As a trans student, you face many unique issues in graduate school, especially if you are also BIPOC or have other minoritized identities. In deciding on a school to attend, it is important to consider not only the climate of the college and in your program of study, but also a number of other factors. This chapter highlights some of the challenges you may encounter in finding a supportive department, institution, and local community; working with advisors, mentors, and other faculty; developing allies; preparing for a career; and looking for a job. Along with offering suggestions for trans grad students, we make recommendations for faculty, student services staff, mental health professionals, and administrators who work with trans grad students.',
    ch_num: 11,
    nav_title: 'Trans in Grad School',
    authors: [
      {
        name: 'Genny Beemyn, Ph.D',
        bio: (<div>
            <p className='reg fontMain'><b>Genny Beemyn, Ph.D.</b> is the director of the UMass Stonewall Center and the coordinator of Campus Pride’s Trans Policy Clearinghouse. They have published and spoken extensively on the experiences and needs of trans college students, including writing some of the first articles on the topic. Among the books they have authored are <i>A Queer Capital: A History of Gay Life in Washington, D.C.</i> and <i>The Lives of Transgender People.</i> Genny’s most recent book is an anthology, <i>Trans People in Higher Education.</i> They are currently writing <i>Campus Queer: Addressing the Needs of LGBTQA+ College Students</i> and co-editing <i>The Encyclopedia of Trans Studies.</i></p>
            <p className='reg fontMain'><MailOutlineIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='mailto: genny@umass.edu'>genny@umass.edu</a></p>
          </div>
        ),
        image: beemyn,
      },
      {
        name: 'Abbie E. Goldberg',
        bio: (<div>
          <p className='reg fontMain'><b>Abbie E. Goldberg</b> is a Professor in the Department of Psychology at Clark University in Worcester, Massachusetts. She received her Ph.D. in clinical psychology from the University of Massachusetts Amherst. Her research examines diverse families, including lesbian- and gay-parent families and adoptive-parent families. A particular focus of her research is key life transitions (e.g., the transition to parenthood, the transition to kindergarten, the transition to divorce) for same-sex couples.  She has also studied the experiences of transgender college students, families formed through reproductive technologies, and bisexual mothers partnered with men. She is the author of over 100 peer-reviewed articles and three books: Gay Dads (NYU Press), Lesbian- and Gay-Parent Families (APA), and Open Adoption and Diverse Families (Oxford). She is the co-editor of LGBTQ-Parent Families: Innovations in Research and Implications for Practice (Springer; 2nd ed), the co-editor of LGBTQ Divorce and Relationship Dissolution (Oxford), the editor of the Encyclopedia of LGBTQ Studies (Sage), as well as the co-editor of the Encyclopedia of Trans Studies (Sage), which is in production. She has received research funding from the American Psychological Association, the Alfred P. Sloan Foundation, the Williams Institute, the Gay and Lesbian Medical Association, the Society for the Psychological Study of Social Issues, the National Institutes of Health, and the Spencer Foundation.</p>
          <p className='reg fontMain'><LanguageIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='https://wordpress.clarku.edu/agoldberg/' target='_blank'>abbiegoldberg.com</a></p>
          <p className='reg fontMain'><TwitterIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='https://twitter.com/DrAbbieG' target="_blank">@DrAbbieG</a></p>
        </div>
        ),
        image: goldberg,
      }
    ]
  },
  {
    name: 'Jess Zonana, M.D.',
    bio: <p className='reg fontMain'><b>Jess Zonana, M.D.</b> is an Assistant Professor of Clinical Psychiatry and spent ten years working as a therapist and psychiatrist for Weill Cornell Medical College's student mental health program, treating biomedical graduate students, medical students, and P.A. students. She currently serves as the Chief of Adult Outpatient Services in the Department of Psychiatry at New York-Presbyterian-Weill Cornell Medical Center where she oversees outpatient clinical programming for the adult psychiatry residency training program. She also serves as Medical Director of the Weill Cornell Medicine Wellness Clinic, a free student-run mental health clinic serving queer and transgender populations in NYC.</p>,
    chapter: 'twelve',
    ch_num: 12,
    image: zonana,
    chapter_title: 'Biomedical Pathways: Graduate Student Well-Being in the Biomedical Sciences',
    description: 'Pathways to a graduate degree in the biomedical sciences offer unique stressors and rewards for students. Navigating the academic environments of biomedical research often require adaptations from previous academic experiences. This chapter offers a framework for understanding some of the individual challenges of finding a lab, working with your PI, managing lab tensions, and planning your post-graduate career. Recognizing your own work habits, personality, and vulnerabilities as you face difficulties in research lab environments can help maintain well-being and personal growth. This chapter will provide guidance in identifying and adapting to individual, interpersonal, and academic obstacles as you grow and work toward your biomedical degree.',
    nav_title: 'Biomedical Sciences',
  },
  {
    name: 'Michelle Chu Camba, Psy.D.',
    bio: <p className='reg fontMain'><b>Michelle Chu Camba, Psy.D.</b> is a New York State licensed clinical psychologist in private practice. She specializes in working with young to middle-age adults, particularly graduate students and young professionals. Dr. Chu Camba identifies as a therapist of color and incorporates a multicultural approach in her clinical work. She has extensive experience providing therapy to clients struggling with multicultural challenges, depression, anxiety, relationship concerns, low self-esteem, identity development, and women's issues.
    Prior to private practice, Dr. Chu Camba worked in college counseling centers and community mental health clinics located in Philadelphia, Baltimore, and New York City. In addition to individual therapy, she enjoys facilitating training seminars for psychology trainees and behavioral health clinicians as well as psychoeducational workshops for community members. 
    Dr. Chu Camba received her Psy.D. in clinical psychology from La Salle University in Philadelphia, PA. She also served as an adjunct professor, teaching Theories of Counseling and Psychotherapy.
    </p>,
    chapter: 'thirteen',
    ch_num: 13,
    image: camba,
    chapter_title: 'Who Am I: My Multicultural Selves',
    description: 'Graduate school can be an exciting yet rigorous and overwhelming journey! Not only are you expected to jump through academic hoops, but you may also encounter challenges navigating your multicultural identities. The truth is—your various identities have a great impact on your overall psychological and emotional functioning. This chapter is here to shout the message: You are not alone! Throughout these pages, personal narratives of your peers are shared to normalize your struggles. Themes related to the sense of belonging and the role of language are explored. Thought-provoking exercises intended to encourage reflecting on your own experiences and how these experiences have contributed to your personal development are offered. This chapter also provides useful suggestions for how you can cope with navigating your multicultural challenges. Additional recommendations on how to support grad students are included for university faculty, staff, and administration, as well as mental health professionals.',
    nav_title: 'My Multicultural Selves',
  },
  {
    name: '​​Phyllis Schulz, Ph.D.',
    bio: (<div>
      <p className='reg fontMain'>​​<b>Phyllis Schulz, Ph.D.</b> earned her doctorate in Higher and Post-Secondary Education from New York University. She has committed the last fourteen years of her career to supporting graduate education. In her current role as Executive Director of Fellowships and Financial Aid at The Graduate Center, Dr. Schulz oversees all financial aid processes for the Graduate School, the CUNY School of Journalism, and the CUNY School of Labor and Urban Studies. Prior to joining The Graduate Center, Dr. Schulz served at Director of Financial Aid at New York University's School of Medicine​. She has served on the editorial board of <i>Journal of Student Financial Aid</i> ​and as a member of the National Association of Student Financial Aid Administrators' Graduate and Professional Task Force.</p>
      <p className='reg fontMain'><MailOutlineIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='mailto: pschulz@gc.cuny.edu'>pschulz@gc.cuny.edu</a></p>
      <p className='reg fontMain'><LinkedInIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='https://www.linkedin.com/in/phyllisschulz/' target="_blank">Linkedin.com/phyllisschulz</a></p>
      <p className='reg fontMain'><TwitterIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='https://twitter.com/phyllisschulz' target="_blank">@PhyllisSchulz</a></p>
    </div>
    ),
    chapter: 'fourteen',
    ch_num: 14,
    image: schulz,
    chapter_title: 'Financing a Graduate Degree: The Practicalities',
    description: `You're excited to apply to graduate school, but one thing stands in your way: How do you pay for it? Attending graduate school is an investment in both time and money. To the uninitiated, financial aid can appear to be a foreign language. This chapter will identify the types of financial assistance available, how to apply and what to expect during the financial aid process.`,
    nav_title: 'Financing a Graduate Degree: The Practicalities',
  },
  {
    name: 'Stephanie Newman PhD, FABP',
    bio: (<div>
      <p className='reg fontMain'><b>Stephanie Newman PhD, FABP</b>, is a psychologist-psychoanalyst, visiting scholar-clinical supervisor of doctoral candidates at Columbia University, faculty member at PANY, affiliated with the School of Medicine at NYU Institute, and a member of The American Psychoanalytic Association’s Committee of Public Information. Dr. Newman has over 20 years’ experience providing insight-oriented talk therapy for those with anxiety, depression, relationship, health and workplace difficulties, and frequently consults with parents on issues like divorce and bullying. She is a co-editor of Money Talks, the author of Mad Men on the Couch, and a regular contributor to Psychology Today on-line. Her writing has appeared in outlets such as Washington Post, Wall Street Journal and nbc.com, and she has recently published Barbarians at the PTA (Skyhorse Publishing), a novel about teens, social media, and maternal identity. Dr. Newman recently gave the keynote speech on cyberbullying at the UFT’s clinical appreciation day. </p>
      <p className='reg fontMain'><LanguageIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='https://psychotherapistnextdoor.com/' target='_blank'>psychotherapistnextdoor.com/</a></p>
      <p className='reg fontMain'><TwitterIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='https://twitter.com/snewmanphd' target="_blank">@snewmanphd</a></p>
      <p className='reg fontMain'><InstagramIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='https://www.instagram.com/stephanienewmanphd' target="_blank">@stephanienewmanphd</a></p>
      <p className='reg fontMain'><FacebookIcon style={{ verticalAlign: 'bottom' }} />{' '}<a href='https://www.facebook.com/BarbariansAtPTA/' target="_blank">Facebook.com/BarbariansAtPTA</a></p>
    </div>
    ),
    chapter: 'fifteen',
    ch_num: 15,
    image: newman,
    chapter_title: 'Financing a Graduate Degree – the Psychology',
    description: 'The life of a graduate student can be stressful. Working long hours to complete coursework, research and teaching obligations while receiving small stipends and carrying heavy debt loads is difficult. Many have limited financial resources, even as their friends in the work force have salaries and disposable income. Disparity in financial resources and differing schedules can leave grad students with a wide range of negative feelings and can contribute to isolation. In this chapter I discuss these and other psychological difficulties inherent in the graduate student experience as well as strategies to maintain motivation and hope.',
    nav_title: 'Financing a Graduate Degree: The Psychology',
  },
  {
    name: 'Karen E. Starr, PsyD',
    bio: <p className='reg fontMain'><b>Karen E. Starr, PsyD</b> is a clinical psychologist and psychoanalyst who teaches writing, conducts writing workshops, and provides one-on-one writing and dissertation consultations. She is Adjunct Clinical Supervisor at the Student Counseling Service at The Graduate Center, City University of New York, where she supervises clinical psychology doctoral students providing academic consultations and short-term psychotherapy to graduate students. She was formerly Visiting Professor and Staff Psychologist at The Graduate Center. Dr. Starr is Faculty and Co-Chair of the Independent Track at the New York University Postdoctoral Program in Psychotherapy and Psychoanalysis, where she completed her psychoanalytic training and teaches the course <i>Writing Psychoanalytically</i> to doctoral-level mental health clinicians. She earned her doctorate in clinical psychology from Long Island University. Dr. Starr is the author of numerous journal articles and books, including <i>Repair of the Soul: Metaphors of Transformation in Jewish Mysticism and Psychoanalysis; A Psychotherapy for the People: Toward a Progressive Psychoanalysis; and Relational Psychoanalysis and Psychotherapy Integration.</i> She maintains a private practice in New York City and Great Neck, New York.</p>,
    chapter: 'sixteen',
    ch_num: 16,
    image: starr,
    chapter_title: 'Getting the Writing Done: Completing Your Paper, Thesis, or Dissertation',
    description: 'Do you need to start writing but don’t know where to begin? Feeling paralyzed by imagined judgmental readers? Having trouble making time to fit writing into your life? If you are in graduate school, be assured you are not alone! Many graduate students find themselves overwhelmed by the sheer amount of writing they have to do and unsure about their ability to do it at the graduate level. Writing a Master’s thesis or doctoral dissertation can feel like an especially daunting proposition, given the mandate to “be original.” This chapter offers practical strategies for engaging with the writing process, including organizing your time, becoming unstuck in your writing, overcoming perfectionism, and dealing with procrastination. It also provides guidance specifically for doctoral students facing the challenge of writing the dissertation, including tips for formulating an original idea, communicating that idea effectively, working with your advisor, and moving forward in a process that is more of a marathon than a sprint.',
    nav_title: 'Getting the Writing Done',
  },
  {
    chapter: 'seventeen',
    chapter_title: 'Taming the Tenacious Beast of Procrastination: Building a Bridge Between Intention & Action',
    description: 'As graduate students, you might find yourselves surrendering to the ever-present trap of procrastination as a short-term way of managing the overwhelming demands of your program. We’ve been there, and we’re aware of every mind trick we play to justify delaying our work. We convince ourselves that we have to organize our room before we can start studying, or that watching another episode of our favorite show will give us the energy we need to begin writing. This chapter first clarifies that we tend to procrastinate, not because we’re lazy or lack self-control, but because we’re unable to manage negative emotions around a task. We will encourage you to become aware of the common cognitive pitfalls that fuel your procrastinating behaviors, work through emotional barriers that might deadlock you in a spiral of shame, and reaffirm your values for engaging in your work. In this chapter, you will learn specific strategies to work around the procrastination trap and approach your work with a sense of self-compassion, presence, and purpose.',
    ch_num: 17,
    nav_title: 'Procrastination',
    authors: [
      {
        name: 'Enid Gertmenian, LCSW',
        bio: <p className='reg fontMain'><b>Enid Gertmenian, LCSW</b>, is a psychotherapist in New York City. She received her undergraduate degree from Eugene Lang College, where she majored in psychology and literature. After coordinating several NIMH-funded research projects, she completed a graduate degree in social work at the Columbia University School of Social Work, focusing on mental health. Since 2008 she has been a faculty member at Columbia University Department of Psychiatry's Day Treatment Program and the Lieber Recovery Clinic. There, she developed a manualized group therapy treatment to address executive functioning skills deficits. Group members work collaboratively to apply motivational theories to their own experiences to reach personal goals and better understand procrastination, avoidance, and perfectionism. With Columbia's <i>Teach Recovery</i> training center (<a href='https://www.collectedmed.com/teachrecovery' target="_blank">teachrecovery.com</a>), she developed a training program to help clinicians engage patients with executive dysfunction to turn their intentions into action. She enjoys working with small groups and individuals in her private practice.  </p>,
        image: gertmenian,
      },
      {
        name: 'Jennifer Lee, PhD',
        bio: <p className='reg fontMain'><b>Jennifer Lee, PhD</b> is a Clinical Psychologist in private practice and Visiting Assistant Professor of Psychology at Soka University in Southern California where she teaches and mentors undergraduate students. She previously served as staff psychologist in various clinical settings including hospitals, schools, and residential treatment programs, and more recently as Assistant Professor of Medical Psychology in Psychiatry at Columbia University Medical Center. As a clinician, Jennifer integrates psychodynamic, cognitive-behavioral, dialectical behavioral, systemic and mindfulness-based approaches to support her clients in their process of self-discovery. In her scholarly work, she has published in the areas of adolescent mental health, ethnic minority identity development, and mindfulness. She co-authored the book, <i>Mindfulness-Based Cognitive Therapy for Anxious Children</i> (New Harbinger Publications) that has now been translated into French, German and Italian, and distinguished as an evidence-based treatment for anxiety. Jennifer earned her undergraduate degree from Cornell University and her doctorate in Clinical Psychology from Teachers College, Columbia University.</p>,
        image: lee,
      }
    ]
  },
]

export default authors;
