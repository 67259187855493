import "react-responsive-carousel/lib/styles/carousel.min.css";
import './home.css';
import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import PreOrderLink from './preOrderLink';
import aboutTransparent from '../../../public/assets/aboutTransparent.png';
import bookCoverCropped from '../../../public/assets/bookCoverCropped.png';
import AmazonLogo from '../../../public/assets/AmazonLogo.png';
import amazon from '../../../public/assets/amazon.png';
import indiebound from '../../../public/assets/indiebound.png';
import barnesnnoble from '../../../public/assets/barnesnnoble.png';
import rl_logo from '../../../public/assets/rl_logo.png';
import { landingReviews } from './landingReviews';
import { useInView } from 'react-intersection-observer';
import { Carousel } from 'react-responsive-carousel';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const AMAZON = 'https://www.amazon.com/Thriving-Graduate-School-Experts-Wellness/dp/1538133296/ref=sr_1_1?dchild=1&keywords=thriving+in+graduate+school&qid=1610465269&sr=8-1';
const BARNES = 'https://www.barnesandnoble.com/w/thriving-in-graduate-school-arielle-shanok/1138400269;jsessionid=2DF84D1D187BD135686CA6B43F5D0018.prodny_store01-atgap03?ean=9781538133293';
const INDIE = 'https://www.indiebound.org/book/9781538133293';
const RL = `https://rowman.com/ISBN/9781538133293/Thriving-in-Graduate-School-The-Expert's-Guide-to-Success-and-Wellness`;

// const SVG = 'https://tigsphotos.s3.us-east-2.amazonaws.com/undraw_education_f8ru.svg';

const Home  = ({ classes }) => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  return (
    <div className={`${classes.toolbar} home-bg`}>
      <div className={`${classes.toolbar} home-page-1`}>

        <div className='home-bg-left'>

          <div className='home-buy-links'>

            <div className='home-title'>
              <p className='textShadow' id='thriving'>Thriving In<br/>Graduate School</p>
              <p className='textShadow' id='pre-order'>Order Now</p>
            </div>

            <div className='pre-order-link-container'>
              <PreOrderLink image={rl_logo} text={null} link={RL} target={true} resize={true} />
              <PreOrderLink image={amazon} text={null} link={AMAZON} target={true} />
              <PreOrderLink image={barnesnnoble} text={null} link={BARNES} target={true} />
              <PreOrderLink image={indiebound} text={null} link={INDIE} target={true} />
            </div>
            <div id='bulk-container'>
              <p className='textShadow' style={{ paddingTop: '20px', fontSize: '1rem' }}>30% DISCOUNT OFFER ON ROWMAN & LITTLEFIELD WEBSITE, USE CODE: RLFANDF30</p>
              <p className='textShadow' id='bulk-orders'>For discounted bulk sales, please  contact - <a href="mailto:special.sales@rowman.com" id='special'>special.sales@rowman.com</a> - for pricing quotes and answers to your questions.</p>
            </div>
            
          </div>

        </div>

        <div className='home-bg-right'>
          <div className='book-cover-container'>
            <img src={bookCoverCropped} alt='book cover' id='book-cover' />
          </div>
        </div>

        </div>

      <div className='home-page-2'>
        <p className='textShadow'>What experts are saying:</p>
        <Carousel
          autoPlay={true}
          interval={5000}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          showArrows={true}
          style={{ width: '80%', overflowX: 'hidden' }}
        >
          {
            landingReviews.map((_quote, idx) => {
              const { quote, by } = _quote;
              return (
                <div key={idx} className='carousel-slide'>
                  <p><i>"{quote}"</i></p>
                  <p>- {by}</p>
                </div>
              )
            })
          }
        </Carousel>
        <a href='/reviews' className='findOutButton home-a-tag' style={{ marginTop: '1rem' }}>
          <button className='reviews-button'>Read More Reviews</button>
        </a>
      </div>

      <div className='home-page-3'>
        <div className='home-about-two'>
          <p className='home-about-text about-text-2' id='target' style={{ color: 'white' }}>Above all else, <i>Thriving in Graduate School: The Expert's Guide To Success and Wellness</i> promotes psychologically healthy approaches to navigating the graduate school experience. What it offers that no other general graduate school success book does is a social-emotional-reflective perspective, which lies at the heart of positive mental health. Students learn skills that help them perform well <i>and</i> feel well. Since rates of depression and anxiety are six times higher among graduate students than the general population, maintaining emotional wellbeing in grad school is vital.</p>
          <div className='home-about-buttons'>
            <a href={'/editors'} className='findOutButton home-a-tag'>
              <button>About The Editors</button>
            </a>
            <a href={'/chapters'} className='findOutButton home-a-tag'>
              <button>About The Authors</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
});


export default withStyles(styles)(Home);
