import './editorials.css';
import React from 'react';
import SingleReview from './singleReview';
import { reviews } from './reviews';

const Editorials = () => {
  return (
    <div className='editorials-container'>
      <div className='editorials-inner'>
        <h1>Editorial Reviews</h1>
        {
          reviews.map((review, idx) => <SingleReview key={idx} review={review} />)
        }
      </div>
    </div>
  );
};

export default Editorials;
