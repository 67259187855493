import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import editors from '../../../public/assets/editors.png';

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
});

const Editors = ({ classes }) => {
  return (
    <div className={`${classes.toolbar} bioBg f`}>
      <div className='bioLeft f center'>
        <div className='imageContainer f center'>
          <img src={editors} className='image boxShadow' />
        </div>
      </div>
      <div className='bioRight f-column center'>
        <h1 className='logoMain textShadow'>About the editors</h1>
        <p className='reg fontMain'>Arielle F Shanok, PhD and Nicole Benedicto Elden, PsyD are clinical psychologists who run a graduate school counseling center serving a diverse community of doctoral and masters students in sciences, arts and humanities. Every day, for over a decade, they sit with grad students collaboratively strategizing about how to overcome grad school challenges in order to thrive. Increasingly, they are also providing consultation to faculty and administrators on how to improve their programs. Arielle and Nicole are also recovering grad students--Arielle earned her doctorate in 2007 from Columbia University. Nicole earned hers in 2006 from Long Island University, CW Post. Furthermore, they are compassionate, patient, wise and moderately funny human beings.</p>
        <p className='reg fontMain'>Each chapter is written by an expert on the chapter topic. Some, like the editors, are directors of graduate university departments. Most are psychologists--experts in helping people increase self-knowledge, build self-esteem and self-compassion, develop healthy relationships and effectively pursue their dreams. Each was once a graduate student and all have committed their careers to helping graduate students excel.</p>
      </div>
    </div>
  )
};

export default withStyles(styles)(Editors);
