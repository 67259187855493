import './nav.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SchoolIcon from '@material-ui/icons/School';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  authors: {
    color: '#ffffff',
  },
});

const Navbar = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleChange = () => {
    setOpen(!open);
  }

  const ListItemLink = (props) => {
    return <ListItem button component="a" {...props} />;
  }

  return (
    <AppBar color='primary' position='sticky'>
      <Toolbar color='primary' className='f s-b'>
        <Link to='/'><SchoolIcon color='secondary' /></Link>
          <div id='desktop-nav'>
            <a href='/editors' className='navLink'><Button color='secondary'>About The Editors</Button></a>
            <a href='/chapters' className='navLink'><Button color='secondary'>Chapters</Button></a>
            <a href='/reviews' className='navLink'><Button color='secondary'>Editorial Reviews</Button></a>
        </div>
        <div id='mobile-nav'>
          <Button onClick={handleChange} color='secondary'><MenuIcon /></Button>
          <Drawer 
            anchor='top' 
            open={open} 
            className={classes.fullList} 
            variant='temporary'
            onClose={handleChange}
          >
            <List>
              <div className='f r-align'>
                <Button onClick={handleChange}><CloseIcon /></Button>
              </div>
              <ListItemLink href="/editors">
                <ListItemText primary="About the Editors" className='bold' />
              </ListItemLink>
              <ListItemLink href='/chapters'>
                <ListItemText primary='Chapters' className='bold' />
              </ListItemLink>
              <ListItemLink href='/reviews'>
                <ListItemText primary='Editorial Reviews' className='bold' />
              </ListItemLink>
            </List>
          </Drawer>
      </div>
      </Toolbar>
    </AppBar>
  )
};

export default Navbar;
