import './chapters.css';
import React, { useState, useEffect } from 'react';
import authors from '../../bios';
import ChapterCard from './chapterCard';

const Chapters = ({ location: { state } }) => {
  const [selected, setSelected] = useState(null);
  // useEffect(() => {
  //   console.log('STATE', state);
  //   if (state && state.preventScroll) {
  //     console.log('YUP');
  //   } else {
  //     window.scrollTo(0,0);
  //   }
  // }, []);
  return (
    <div className='chapters-bg'>
      <h1><span>Thriving In Graduate School:</span> The Chapters</h1>
      {
        authors.map(ch => (
          <ChapterCard 
            key={ch.ch_num}
            chapter={ch}
            selected={selected}
            setSelected={setSelected}
          />
        ))
      }
    </div>
  );
};

export default Chapters;
