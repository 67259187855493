import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import authors from '../../bios';
import SingleAuthor from './singleAuthor';
import Multiauthor from './multiAuthor';

const AuthorBio = ({ match: { params: { chapter } } }) => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);
  const author = authors.find(a => a.chapter === chapter);
  return !author.authors ? <SingleAuthor author={author} /> : <Multiauthor chapter={author} />
}
export default AuthorBio;
