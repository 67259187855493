import React from 'react';

export const reviews = [
  {
    text: `"Over the years, higher education has been trying to encourage more students from historically marginalized backgrounds to attend graduate school, but education systems - especially grad school - aren’t particularly built to be a welcoming and nurturing climate for such students. Dr. Benedicto Elden and Dr. Shanok's “Thriving in Graduate School: The Experts Guide to Success and Wellness” is a big step toward the right direction, as it explores common struggles faced by historically marginalized students - such as impostor syndrome, microaggressions, multicultural competence, and funding - and provides practical ways to help students not only succeed in graduate school, but also do so while keeping their wellness. Graduate programs throughout the country should have a copy of this book to share with their students."`,
    by: <p> — E. J. R. David is Associate Professor of Psychology at the University of Alaska Anchorage. He is the author of <i>Brown Skin, White Minds: Filipino -/ American Postcolonial Psychology,</i> editor of <i>Internalized Oppression: The Psychology of Marginalized Groups,</i> and coauthor of <i>The Psychology of Oppression</i></p>
  },
  {
    text: `"In the midst of pandemic and austerity, swelling evidence of anxiety and depression, thrilling movements for racial and gender justice and higher ed in free fall, Thriving in Graduate School offers a jazz of voices that will comfort, resonate, infuriate and offer up brilliant strategies for moving through  - with scholarly lust, activist passions, with babies, and partners (who do and don't get it); thriving and confronting racial microaggressions, homophobic texts and neoliberalism; with rent overdue, some harassing faculty, a few amazing mentors, secretaries who are underpaid and hold the academic world together -- in the  midst you will find a lava like fountain of academic purpose. Or at least the energy to write another 300 words a day and care for your self/selves.  The essays read like poetry for the almost Ph.D. - some pieces will invite you to laugh, others to cry, a few to write nasty emails (that you won't necessarily send) - but on these pages - even in lock down - you will meet sisters and brothers and trans-colleagues who recognize and name the journey through an institution as oppressive as it can be liberating.  Arielle Shanok and Nicole Benedicto Elden offer you a GPS for navigating the joyful and infuriating pathway to graduation and to re-member your intellectual projects of desire and purpose.  Bravo to the editors, authors and the students who lay in bed wondering if it's worth it!"`,
    by: <p> — Michelle Fine is a Distinguished Professor of Critical Psychology, Women’s Studies, American Studies and Urban Education with a focus on social justice at the CUNY Graduate Center. She is the founding faculty member of the Public Science Project.</p>
  },
  {
    text: `“Thriving in Graduate School is an outstanding volume that should be read by those applying to graduate school, those enrolled in graduate programs, faculty and administrators responsible to educate graduate students and mental health professionals committed to providing psychological services to students. This volume brings together an incredibly diverse narrative of actual lived experiences, relevant research evidence that applies to a range of common graduate school experiences across a wide spectrum of academic programs as well as the diverse individual identities of students. Realistic and optimistic suggestions, or takeaways, are offered at the end of each chapter, about how to manage consequential relationships and the unique challenges of the graduate school journey.”`,
    by: <p> — Robert Keisner, Ph.D. is a Professor of Psychology and the former Director of the Clinical Psychology Doctoral Program at Long Island University, NY.</p>
  },
  {
    text: `“Thriving in Graduate School: The Experts Guide to Success and Wellness is a refreshing book for graduate students. Through a simple, engaging, and casual language, the authors demystify the experience of graduate work normalizing it and validating the tough experiences lived by students. Issues such as impostor syndrome, procrastination, and anxiety are presented in a candid way offering self-reflection exercises, and wisdom to empower students to overcome these and other challenges. The book offers suggestions for students, faculty, administrators, and mental health providers in the support of graduate students. This way, the authors offer a holistic approach easy to follow. This is a great book for first-generation graduate students. Last but not least, cultural diversity is addressed with great sensitivity. This is a great book not only for graduate students but all staff working in academic settings serving students.”`,
    by: <p> — Dinelia Rosa, Ph.D. is the Director of the Dean-Hope Center of Educational & Psychological Services, Teachers College, Columbia University’s training clinic, former president of the New York State Psychological Association, Director of Clinical Training at TC Resilience Center for Veterans & Families and Adjunct Full Professor of the Teachers College Clinical Psychology Program</p>
  },
  {
    text: `“Graduate school can be a profoundly deepening and life-changing opportunity.  Still, for students who feel stuck along the way, the perceived barriers can be experienced as depressing, anxiety provoking and insurmountable.  Dr. Shanok and Dr. Elden draw on their years of experience as psychotherapists for university students to show pathways in, through and around these seemingly high gates of passage.  What do Dr. Shanok and Dr. Elden reveal about this leg of career advancement as psychologists?  Quite often our barricades to success are self-constructed.  Success comes in large part from clarity about our own inner life, our chosen stance towards whatever or whomever comes our way, and making a positive impact on those people, whether senior or peers, who surround us.”`,
    by: <p> — Lisa Miller is Professor at Teachers College, Columbia University, Founder of Spirituality and Mind-Body Institute and author of New York Times Bestseller <i>The Spiritual Child: The New Science of Parenting for Health and Lifelong Thriving</i></p>
  },
  {
    text: `“So true to what I’ve been hearing for decades from graduate students, this astute book touches all the right chords in addressing the stressors that students across multiple graduate programs inevitably encounter. Its tone is perfect: written in an accessible, non-academic, user-friendly manner and focusing not only on the enduring problems of graduate students—too much work, too great a degree of perfectionism and too much identification with imposter syndrome, and too much uncertainty regarding one’s relationship with one’s mentor---but also issues more central to the emerging and salient issues of our time, issues related to multi-culturalism, identity, privilege, meaning, and work-life balance. There are multiple true-to-life examples in every chapter as well as hands-on exercises and strategies pulled from a diverse range of psychotherapeutic and other healing traditions. The authors within this edited volume also reflect a wide range of backgrounds and traditions. In sum, this is a book that will surely be of great value to students, academics, and administrators, all of whom have been searching for new and better ways to cope with the enduring problems that always co-exist with the rewards of graduate education.”`,
    by: <p> — Barry A. Farber, PhD is long time professor and the former Director of Clinical Training at Teachers College, Columbia University’s Program in Clinical Psychology</p>
  },
  {
    text: `"Dr. Shanok and Dr. Elden provide readers with a detailed description of the individual dynamics and external pressures encountered in graduate school with a how-to menu of techniques to cope with these challenges.  Their suggestions and recommendations are relevant and useful, not just for graduate students, but for all those who have busy, demanding lives with complex choices about their careers."`,
    by: <p> — N. Mendie Cohn is a clinical psychologist and the former Director of the Clinical Psychology Internship Training Program at  Kings County Hospital Center in Brooklyn, NY.</p>
  },
  {
    text: `"This is an authentic, realistic, practical, useful and usable guide to success and wellness--not just in grad school, but for life in general. I think EVERY grad student should be given a free copy of the book upon acceptance."`,
    by: <p> — Arthur Ulene is the founder and CCO of LifeIsOn at Feeling Fine.  He is a physician, author, educator, public speaker and a former medical correspondent for NBC’s The Today Show.</p>
  },
];
