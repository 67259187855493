import './editorials.css';
import React from 'react';

const SingleReview = ({ review }) => {
  return (
    <div className='editorials-review-container boxShadow'>
      <div className='single-review-top'>
        <p><i>{review.text}</i></p>
      </div>
      <div className='single-review-bottom'>
        {
          review.by
        }
      </div>
    </div>
  );
};

export default SingleReview;
